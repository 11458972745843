import { useEffect } from 'react';
import { useChatWidget } from 'hooks/use-chat-widget';

const useDisableDocumentScroll = (isScrollDisabled: boolean) => {
  useEffect(() => {
    const { userAgent } = navigator;

    let scrollbarWidth: number;
    if (userAgent.indexOf('Firefox') > -1) {
      scrollbarWidth = 17.26;
    } else {
      scrollbarWidth = 2.66;
    }

    const toggleScroll = (shouldBlock: boolean) => {
      const translateValue = shouldBlock ? scrollbarWidth : 0;
      document.documentElement.style.overflow = shouldBlock ? 'hidden' : '';
      document.documentElement.style.paddingRight = shouldBlock ? `${scrollbarWidth}px` : '';
      useChatWidget().setTransform(`translateX(-${translateValue}px)`);
    };

    toggleScroll(isScrollDisabled);

    return () => toggleScroll(false);
  }, [isScrollDisabled]);
};

export default useDisableDocumentScroll;
